<template>
  <div class="d-flex justify-content-between w-100">
    <div class="w-75"></div>
    <router-link
      class="w-25 text-right"
      v-if="viewAllUrl !== ''"
      :to="viewAllUrl"
      title=""
    >
      <button class="btn btn-xs btn-pill subscribe">
        {{ $t("viewAll") }} <i class="icon-next"></i>
      </button>
    </router-link>
    <button
      @click="
        $router.replace({ query: { ...$route.query, ...sectionUpdatePath } })
      "
      v-if="editable"
      class="donate btn-pill btn"
    >
      {{ $t("updateSection") }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    viewAllUrl: {
      default: "",
    },
    title: {
      default: "",
    },
    newProductPath: {
      default: "",
    },
    editable: {
      default: false,
      type: Boolean,
    },
    sectionUpdatePath: {
      default: () => {
        return {
          modal: "opened",
        };
      },
    },
  },
};
</script>
